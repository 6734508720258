<template>
  <div>
    <h2 class="header-required">Meewerkenden (Contributors)</h2>

    <page-snippet name="contributors" />

    <b-table
        ref="contributorTable"
        small 
        :fields="contributorTableFields"
        :items="model.Contributors">

      <template v-slot:cell(DisplayPriority)="data">
        {{ 100 - data.item.DisplayPriority }}
      </template>

      <template v-slot:cell(Name)="data">
        {{ data.item.NamePart1 }} {{ data.item.NamePart2 }} {{ data.item.NamePart3 }}
      </template>

      <template v-slot:cell(actions)="data">
          <b-icon-pencil-square @click="editItem(data)"></b-icon-pencil-square>
          <b-icon-x @click="deleteItem(data)"></b-icon-x>
          <b-icon-chevron-double-right 
              @click="$emit('copyToTracks', 'Contributor', null, {...data.item})" 
              v-if="copyToTracks"
              v-b-tooltip.hover
              title="Kopieer naar alle tracks" />
      </template>
    </b-table>

    <b-modal
      v-if="editingContributor"
      no-close-on-esc no-close-on-backdrop hide-header-close
      v-model="showEditor"
      scrollable
      size="xl"
      id="edit-contributor" 
      title="Contributor"
      hide-footer
      >


      <b-form-group
        :class="{ required: true, 'mt-4': true }"
        id="nameType-group"
        label="Naam type"
        label-for="nameType-select">

        <b-form-select
          id="nameType-select"
          v-model="editingContributor.NameType"
          :options="nameTypeOptions"
        />
      </b-form-group>

      <div v-if="editingContributor.NameType == 'person'">
        <b-form-group
          :class="{ 'mt-4': true }"
          id="name1-group"
          label="Voornaam"
          label-for="part1-input">

          <b-form-input
            id="part1-input"
            ref="part1-input"
            v-model="editingContributor.NamePart1"
            type="text"
          />
        </b-form-group>

        <b-form-group
          :class="{ 'mt-4': true }"
          id="name3-group"
          label="Tussenvoegsel en achternaam"
          label-for="part3-input">

          <b-form-input
            id="part3-input"
            ref="part3-input"
            v-model="editingContributor.NamePart3"
            type="text"
          />
        </b-form-group>
      </div>

      <div v-if="editingContributor.NameType == 'entity'">
        <b-form-group
          :class="{ 'mt-4': true }"
          id="name3-group"
          label="Naam"
          label-for="part3-input">

          <b-form-input
            id="part3-input"
            ref="part3-input"
            v-model="editingContributor.NamePart3"
            type="text"
          />
        </b-form-group>
      </div>

      <b-form-checkbox
        :id="'contributor-name-auto-formatting'"
        v-model="editingContributor.AutoFormatting"
      >
        Automatisch standaard hoofdlettergebruik / stijl toepassen
      </b-form-checkbox>

      <field 
        :defs="defs"
        name="Contributors.Role"
        :model="editingContributor" />

      <field 
        :defs="defs"
        name="Contributors.DisplayPriority"
        :model="editingContributor" />

      <field 
        :defs="defs"
        name="Contributors.Country"
        :model="editingContributor" />

      <b-alert show v-for="(warning, index) in warnings" :key="index" variant="warning" v-html="warning"> 
      </b-alert>
        
      <b-alert show v-for="(error, index) in errors" :key="index" variant="danger">
        {{ error }}
      </b-alert>
        
      <b-button @click="errors = []; editingIndex = null; editingContributor = null" variant="secondary">Annuleer</b-button>

      <b-button @click="onEditOk" variant="primary" class="ml-3" :disabled="fullName == '' || !editingRole">
        <template v-if="fullName == '' || !editingRole">
          Toevoegen
        </template>

        <template v-if="fullName != '' && editingRole">
          Voeg '{{ fullName }}' toe als {{ editingRole }}
        </template>

      </b-button>

    </b-modal>

    <b-button @click="add">Contributor toevoegen</b-button>
    <b-button class="ml-4" v-if="type == 'album'" @click="copyFromTracks">Kopieer contributors van tracks</b-button>

    <b-alert show v-for="(error, index) in fieldErrors" :key="index" variant="danger" v-html="error" class="mt-4">
    </b-alert>

    <!--
    <div class="mt-3" v-if="copyToTracks"><a href="#" @click.prevent="$emit('copyToTracks', 'Contributors')">Toepassen op alle tracks</a></div>
    -->
  </div>
</template>

<script>
  import { formatName } from '@/functions'

  export default {
    name: 'ReleaseConceptContributors',
    components: {
      'page-snippet': () => import('@/components/PageSnippet'),
      'field': () => import('@/components/ReleaseConceptField'),
    },
    data () {
      return {
        errors: [],
        showEditor: false,
        editingContributor: null,
        editingIndex: null,
        nameTypeOptions: [
          {value: 'person', text: 'Persoon'},
          {value: 'entity', text: 'Entiteit (Bedrijf, Band)'},
        ],
        contributorTableFields: [
          {
            key: 'DisplayPriority',
            label: 'Volgorde',
          },
          {
            key: 'Name',
            label: 'Naam',
          },
          {
            key: 'Role',
            label: 'Rol',
          },
          {
            key: 'Country',
            label: 'Land',
          },
          {
            key: 'actions',
            label: 'Acties',
            class: 'link-icons',
          },
        ]
      }
    },
    props: {
      model: Object,
      validationErrors: Object,
      type: String,
      defs: Object,
      copyToTracks: {
        type: Boolean,
        default: false
      }
    },
    methods: {
      copyFromTracks () {
        if (!this.model.Contributors)
          this.$set(this.model, 'Contributors', [])

        this.model.Tracks.forEach(track => {
          if (track.UseExisting) {
            track = track.ExistingData
          }

          if (track.Contributors) {
            track.Contributors.forEach(c => {
              if (c.Type == 'Primary') {
                if (!this.model.Contributors.some(c2 => JSON.stringify(c) == JSON.stringify(c2))) {
                  this.model.Contributors.push(JSON.parse(JSON.stringify(c)))
                }
              }
            })
          }
        })
        this.$emit('change')
      },
      add () {
        this.showEditor = true
        this.editingIndex = 'new'
        this.editingContributor = {
          DisplayPriority: 1,
          NameType: 'person',
          AutoFormatting: true,
        }
      },
      deleteItem (item) {
        this.model.Contributors.splice(item.index, true);
        this.$emit('change')
      },
      editItem (item) {
       this.editingIndex = item.index
       
       let c = {...this.model.Contributors[item.index]}
       c.DisplayPriority = 100 - c.DisplayPriority
       this.editingContributor = c
       this.showEditor = true
      },
      onEditOk () {
        if (!this.model.Contributors) {
          this.$set(this.model, 'Contributors', [])
        }

        if (this.editingContributor.AutoFormatting) {
          this.editingContributor.NamePart1 = formatName(this.editingContributor.NamePart1)
          this.editingContributor.NamePart2 = formatName(this.editingContributor.NamePart2)
          this.editingContributor.NamePart3 = formatName(this.editingContributor.NamePart3)
        }

        // Validate
        this.errors = []
        if (!this.editingContributor.NamePart3.trim()) {
          if (this.editingContributor.NameType == 'person')
            this.errors.push('Achternaam van persoon is verplicht')
          else
            this.errors.push('Naam is verplicht')

        }

        if (this.fullName.indexOf(',') != -1) {
          this.errors.push('Komma\'s zijn niet toegestaan in contributor namen.')
        }

        if (!this.editingContributor.Role) {
          this.errors.push('Rol is verplicht')
        }

        if (this.errors.length > 0) return

        // Fix
        this.editingContributor.DisplayPriority = 100 - this.editingContributor.DisplayPriority 

        this.editingContributor.Type = [
              'Recording Artist',
              'Featuring Artist',
              'ReMixer',
           ].includes(this.editingContributor.Role) ? 'Primary' : 'Default'
        
        if (this.editingIndex == 'new') {
          this.model.Contributors.push({...this.editingContributor})
        } else {
          let contribs = [...this.model.Contributors]
          contribs[this.editingIndex] = {...this.editingContributor} 
          this.$set(this.model, 'Contributors', contribs)
        }

        this.editingIndex = null
        this.editingContributor = null 
        this.$emit('change')
      },
    },
    computed: {
      warnings  () {
        let warnings = []
        let name = this.fullName
        if (name.split(' ').length > 4 || [' en ', '&', '/'].some(i => name.includes(i))) {
          warnings.push(this.$root.$data.pageSnippets['validate-compound-artist'])
        }

        let numCaps = name.replace(/[^A-Z]/g, '').length
        let numNormal = name.replace(/[^a-z]/g, '').length
        if ((numCaps+numNormal > 3) && (numCaps >= numNormal)) {
          warnings.push('De naam bevat 50% of meer hoofdletters, dat is vaak niet de bedoeling.')
        }
        return warnings
      },
      editingRole () {
        if (!this.editingContributor.Role) return false
        return this.defs['Contributors.Role'].Options.find(r => r.JsonValue == this.editingContributor.Role).Value
      },
      fullName () {
        let name = []
        if (this.editingContributor.NamePart1) {
          if (this.editingContributor.AutoFormatting)
            name.push(formatName(this.editingContributor.NamePart1))
          else
            name.push(this.editingContributor.NamePart1)
        }
        
        if (this.editingContributor.NamePart2) {
          if (this.editingContributor.AutoFormatting)
            name.push(formatName(this.editingContributor.NamePart2))
          else
            name.push(this.editingContributor.NamePart2)
        }
        
        if (this.editingContributor.NamePart3) {
          if (this.editingContributor.AutoFormatting)
            name.push(formatName(this.editingContributor.NamePart3))
          else
            name.push(this.editingContributor.NamePart3)
        }

        return name.join(' ').trim()
      },
      fieldErrors () {
        if (!this.validationErrors || !this.validationErrors['Contributors']) return []
        return this.validationErrors['Contributors']
      }
    },
    mounted () {

    }
  }
</script>

<style lang="scss">
  .modal-xl {
    max-width: 80vw !important;
  }

  table td.link-icons {
    a {
      font-weight: normal;
      color: black;
    }

    font-size: 1.65rem;
    
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
  }
</style>